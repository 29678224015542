<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          <span
            class="label label-inline label-pill label-info label-rounded mr-2"
          >
            Инструкция:
          </span>
          Здесь вы можете отредактировать данные вашего профиля. Для изменения
          пароля вам надо будет указать текущий пароль и новый.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="card">
      <div class="card-header card-header-tabs-line"></div>

      <!--begin::Form-->
      <div class="card-body">
        <div class="login-form login-signup">
          <div class="pb-13 pt-lg-0 pt-5">
            <h3
              class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
            >
              Профиль
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
              Отредактируйте указанные значения и нажмите кнопку "Обновить"
            </p>
          </div>
          <div class="form-group" v-show="'messageNew' in errors">
            <div
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ errors.messageNew }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Имя"
              v-model="currentUserPersonalInfo.name"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              placeholder="Фамилия"
              v-model="currentUserPersonalInfo.surname"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="email"
              placeholder="Email"
              v-model="currentUserPersonalInfo.email"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>
          <!--<div class="form-group">
              <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                type="text"
                placeholder="Организация"
                v-model="currentUserPersonalInfo.company_name"
                autocomplete="off"
                @change="delete errors.messageNew"
              />
            </div>-->
          <!--<div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="password"
              placeholder="Текущий пароль"
              v-model="currentPassword"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>-->
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="password"
              placeholder="Новый пароль"
              v-model="newPassword"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="password"
              placeholder="Повтор нового пароля"
              v-model="repeatPassword"
              autocomplete="off"
              @change="delete errors.messageNew"
            />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-9">
            <button
              class="btn btn-primary font-weight-bold"
              @click.prevent="submitUpdate"
            >
              <i class="la la-check-circle"></i>
              Обновить
            </button>
          </div>
        </div>
      </div>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";

export default {
  name: "profile",
  components: {},
  data() {
    return {
      errors: [],
      currentPassword: "",
      newPassword: "",
      repeatPassword: ""
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUserPersonalInfo"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Профиль" }]);
  },

  methods: {
    submitUpdate() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.currentUserPersonalInfo.name.trim() === "" ||
        this.currentUserPersonalInfo.surname.trim() === "" ||
        this.currentUserPersonalInfo.email.trim() === "" ||
        !re.test(String(this.currentUserPersonalInfo.email).toLowerCase()) ||
        (this.newPassword.trim() !== "" &&
          (this.currentPassword.trim() === "" ||
            this.newPassword.trim() !== this.currentPassword.trim())) ||
        (this.newPassword.trim() === "" && this.currentPassword.trim() !== "")
      ) {
        Swal.fire({
          title: "",
          text: "Пожалуйста, введите корректные данные!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      } else {
        this.updateAccount();
      }
    },

    checkCurrentPasswordValid() {
      //TODO
    },

    updateAccount() {
      var accountData = {
        username: this.currentUserPersonalInfo.email,
        email: this.currentUserPersonalInfo.email,
        first_name: this.currentUserPersonalInfo.name,
        last_name: this.currentUserPersonalInfo.surname,
        password: this.repeatPassword
      };
      if (this.newPassword === "") {
        delete accountData.password;
      }
      return new Promise(resolve => {
        ApiService.patch(
          "api/v1/users/" + this.$store.getters.currentUser.user.id + "/",
          accountData
        ).then(({ data }) => {
          //  console.log(data);
          Promise.all([this.$store.dispatch(VERIFY_AUTH)]).then(function() {
            Swal.fire({
              title: "Успешно!",
              text: "Данные успешно изменены!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
            resolve(data);
          });
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    }
  }
};
</script>
